.timeline {
  list-style: none;
  margin-top: 80px;
  padding: 0 20px 0 50px;
  position: relative;
  max-width: 800px;
  &:before {
    content: '';
    position: absolute;
    height: 100%;
    border-left: 1px solid #ccc;
    left: 20px;
  }
  p {
    margin-top: 20px;
    &.skills {
      margin-top: 10px;
    }
  }
  li {
    margin-bottom: 70px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      border: 1px solid #ccc;
      background-color: #ccc;
      opacity: 0.8;
      border-radius: 50%;
      left: -38px;
      top: 0px;
    }
    .year {
      min-width: 120px;
      padding: 0 15px 0 0;
    }
  }
}

.skills {
  color: #f5deb8;
  font-family: serif;
  font-weight: normal;
  font-style: italic;
}

@media screen and (min-width: 460px) {
  .timeline {
    padding-left: 80px;
    li:before {
      left: -68px;
    }
  }
}
