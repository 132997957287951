.swiper-content {
  width: 100%;
  padding: 20px 65px;
  p {
    margin-bottom: 4px;
  }
  .first-paragraph {
    margin-top: 30px;
  }
}
.content-wrapper {
  width: 100%;
  display: flex;
  gap: 20px;
  .item {
    flex-grow: 1;
  }
  .first-paragraph {
    margin-bottom: 20px;
  }
  .story-img {
    display: none;
    background-position: center;
    background-size: cover;
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border: 1px solid #ccc;
      left: 10px;
      top: 10px;
      opacity: 0.5;
    }
  }
}

@media screen and (min-width: 768px) {
  .content-wrapper {
    .story-img {
      display: block;
      min-width: 300px;
    }
  }
}
