
.navigation {
  margin-top: 100px;
  margin-bottom: 100px;
  .nav-card {
    margin: 40px 30px;
    border-radius: 3px;
    height: 100px;
    display: flex;
    align-items: center;
    background-position: center;
    background-size: cover;
    position: relative;
    cursor: pointer;
    .img-card {
      width: 100%;
      max-width: 400px;
      height: 120px;
      background-size: contain;
      background-position: center;
      transition: transform 3000ms cubic-bezier(0.075, 0.82, 0.165, 1);
      border-radius: 5px;
    }
    h4 {
      font-family: serif;
      font-weight: 400;
      font-size: 1.8em;
      font-style: italic;
      letter-spacing: -0.06em;
      width: 100%;
      text-align: left;
      padding-left: 20px;
      z-index: 1;
      position: relative;
      transition: padding 2000ms cubic-bezier(0.075, 0.82, 0.165, 1);
      &:after {
        content: '';
        position: absolute;
        width: 80%;
        border-bottom: 1px solid #ccc;
        bottom: -5px;
        left: -50%;
      }
    }
    &.right {
      h4 {
        padding-left: 0;
        padding-right: 20px;
        text-align: right;
        &:after {
          left: 50%;
        }
      }
    }
    &:hover {
      .img-card {
        transform: scale(1.1);
      }
      h4 {
        padding-left: 2px;
      }
      &.right h4 {
        padding-right: 5px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .navigation {
    .nav-card {
      .img-card {
        height: 200px;
      }
    }
  }
}