.project-content {
  grid-template-columns: 1fr;
  display: grid;
}
.project-text {
  padding: 0 50px 20px;
  h2 {
    margin-bottom: 20px;
  }
}
.big-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 200px;
}

@media screen and (min-width: 768px) {
  .project-content {
    grid-template-columns: repeat(2, 50%);
  }
  .project-text {
    padding-left: 20px;
  }
}
