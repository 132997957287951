@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Inter:wght@100;200;300;400;500;600&family=Tourney:wght@100&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  position: relative;
}
html {
  font-size: 16px;
}
html,
body,
.root,
.app {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  font-family: 'Inter', sans-serif;
  color: #ddd;
  background: linear-gradient(270deg, #3095c5, #8e1f80, #bdaa0c);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 30s ease infinite;
  -moz-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;
}
.wrapper {
  max-width: 1120px;
  margin: 0 auto;
}
.opening-text {
  padding: 20px;
  h1 {
    font-family: 'Abril Fatface', cursive;
    font-weight: normal;
    letter-spacing: 0.02em;
    font-size: 3em;
  }
  h2,
  h3 {
    font-weight: 200;
    margin-top: 20px;
    letter-spacing: -1px;
    font-size: 1.2em;
  }
}

.title {
  font-family: serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: -0.06em;
  line-height: 0.6em;
}
h2.title {
  position: relative;
  font-size: 1.9em;
  &:after {
    content: '';
    position: absolute;
    border-bottom: 1px solid #ccc;
    width: 100px;
    height: 1px;
    top: 8px;
    margin-left: 0px;
  }
}
h3.title {
  font-size: 1.2em;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.swiper {
  width: 100%;
  margin-top: 40px;
  border: 1px solid #ccc;
  --swiper-navigation-color: #fff;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    left: -10px;
    top: -10px;
  }
}
.swiper-wrapper {
  display: flex;
  align-items: center;
}
.swiper-button-prev,
.swiper-button-next {
  &:after {
    display: none;
  }
  &:before {
    font-family: 'Tourney';
    font-size: 4em;
  }
}
.swiper-button-prev:before {
  content: '<';
}
.swiper-button-next:before {
  content: '>';
}

@media screen and (min-width: 460px) {
  .title {
    line-height: 0.3em;
  }
}

@media screen and (min-width: 768px) {
  .swiper {
    &:before {
      display: none;
    }
  }
}
