.last-slide {
  padding: 20px 0 20px 20px;
  .subcontent {
    line-height: 1.2em;
  }
  .quotes {
    text-align: center;
    margin-top: 20px;
    padding-right: 15px;
    font-family: serif;
    font-style: italic;
  }
}

@media screen and (min-width: 768px) {
  .last-slide {
    .subcontent {
      line-height: 1em;
    }
  }
}