footer {
  font-size: 1.4em;
  padding: 20px;
  &:after {
    content: '';
    border-bottom: 1px solid #ccc;
    width: 100%;
    position: absolute;
    top: 50%;
  }
  a {
    margin-right: 10px;
    padding: 5px 8px;
    border-radius: 50%;
    border: 1px solid #ccc;
  }
}

@media screen and (min-width: 768px) {
  footer {
    position: fixed;
    bottom: 0;
    right: 0;
    &:after {
      display: none;
    }
  }
}
