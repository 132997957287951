.header {
  width: 100%;
  padding: 15px;
  font-family: serif;
  display: flex;
  justify-content: space-between;
  h1 {
    margin: 0;
    font-size: 1em;
    font-weight: 500;
    font-style: italic;
  }
}
.back-nav {
  text-indent: -9999px;
  width: 40px;
  border-bottom: 1px solid #ccc;
  position: relative;
  &:before {
    content: '';
    width: 20px;
    height: 20px;
    left: 3px;
    top: 8px;
    position: absolute;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    transform: rotate(45deg);
  }
}
